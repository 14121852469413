// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

export default function BlockBanner() {
  return (
    <div className="block block-banner">
      <div className="container">
        <Link to="/shop/categories" className="block-banner__body">
          <div
            className="block-banner__image block-banner__image--desktop d-block"
            style={{ backgroundImage: 'url("images/banners/homebanner.png")' }}
          />
          <div
            className="block-banner__image block-banner__image--mobile d-none"
            style={{ backgroundImage: 'url("images/banners/homebanner.png")' }}
          />
          <div className="block-banner__title" style={{ paddingTop: '50px' }}>
            Complete Your Meal From Our Pantry
            <br className="block-banner__mobile-br" />
          </div>
          <div className="block-banner__text">
            Add Pasta, Sauces, Cheeses, Fresh produce and Desserts to your next order!
          </div>
          {/* <div className="block-banner__button"><span className="btn btn-sm btn-primary">Shop Now</span></div> */}
        </Link>
      </div>
    </div>
  );
}
