export default {
  name: 'Cottage Butcher',
  fullName: 'Cottage Butcher',
  // url: 'https://instagram.com/thecottagebutcher_bala',
  author: {
    name: 'Kos',
    profile_url: 'https://instagram.com/thecottagebutcher_bala',
  },
  authorFacebook: {
    name: 'Kos',
    profile_url: 'https://www.facebook.com/profile.php?id=100068671093186',
  },
  contacts: {
    address: '3173 Muskoka District Road 169, Bala,ON P0C 1A0, Canada',
    email: 'info@cottagebutcher.com',
    phone: '705 762 5995',
  },
};
