// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';

export default function Footer() {
  const informationLinks = [
    { title: 'Reward Program ', url: '/site/reward-program' },
    { title: 'Local Delivery', url: '/site/local' },
    { title: 'Delivery Guarantee', url: '/site/delivery-guarantee' },
    { title: 'Returns Policy', url: '/site/returns-policy' },
    { title: 'Pick Up Order', url: '/site/pick-up-order' },
    { title: "FAQ'S", url: '/site/questions-page' },
    // { title: 'Best offer', url: `/shop/category/${process.env.REACT_APP_BEST_CATEGORY_ID}/best-offers` },
    // { title: 'Order Shipping ', url: '/site/order-add' },
  ];

  const accountLinks = [
    { title: 'Store Location', url: '/site/StoreLocation' },
    { title: 'Order History', url: '/account/orders' },
    // { title: 'Wish List', url: '' },
    // { title: 'Blog', url: '/blog/category-classic' },
    // { title: 'Specials', url: '' },
    // { title: 'Gift Certificates', url: '' },
    // { title: 'Affiliate', url: '' },
  ];

  const otherLinks = [
    { title: 'About Us', url: '/site/about-us' },
    { title: 'Contact Us', url: '/site/contact-us' },
    { title: 'Product Recall ', url: '/site/product-recall' },
    { title: 'Terms & Conditions', url: '/site/terms-and-conditions' },
    { title: 'Referring A Friend ', url: '/site/referringFriend' },
    { title: 'Main Site ', url: 'https://cottagebutcher.com', external: true },
  ];

  return (
    <div className="site-footer">
      <div className="container">
        <div className="site-footer__widgets">
          <div className="row">
            <div className="col-12 col-md-6">
              <FooterContacts />
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <FooterLinks title="Information" items={informationLinks} />
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <FooterLinks title="Other Links" items={otherLinks} />
            </div>
            <div className="col-12 col-md-12 col-lg-2">
              <FooterNewsletter />
            </div>
          </div>
        </div>

        <div className="site-footer__bottom d-none">
          <div className="site-footer__copyright">
            Powered by{' '}
            <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">
              React
            </a>{' '}
            — Design by{' '}
            <a href={theme.author.profile_url} target="_blank" rel="noopener noreferrer">
              {theme.author.name}
            </a>
          </div>
          <div className="site-footer__payments">
            <img src="images/payments.png" alt="" />
          </div>
        </div>
      </div>
      <ToTop />
    </div>
  );
}
